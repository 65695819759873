import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import hark from 'hark';
import { useDyteMeeting } from '@dytesdk/react-web-core';
// Uses Hark module to listen to the participant's audio stream to detect whether it is speaking or not
export function useSpeakingParticipantIds() {
    const { meeting } = useDyteMeeting();
    const [speakingParticipants, setSpeakingParticipants] = useState({});
    const harkInstanceRef = useRef(null);
    const speakingOnListener = useCallback((id) => () => {
        setSpeakingParticipants((prev) => (Object.assign(Object.assign({}, prev), { [id]: true })));
    }, []);
    const speakingOffListener = useCallback((id) => () => {
        setSpeakingParticipants((prev) => (Object.assign(Object.assign({}, prev), { [id]: false })));
    }, []);
    const cleanupHarkInstance = useCallback(() => {
        if (harkInstanceRef.current) {
            harkInstanceRef.current.off('speaking', speakingOnListener);
            harkInstanceRef.current.off('stopped_speaking', speakingOffListener);
            harkInstanceRef.current.stop();
            harkInstanceRef.current = null;
        }
    }, [speakingOnListener, speakingOffListener]);
    useEffect(() => {
        const audioUpdateListener = ({ audioEnabled, audioTrack, id, }) => {
            cleanupHarkInstance();
            if (audioEnabled && audioTrack != null) {
                const stream = new MediaStream();
                stream.addTrack(audioTrack);
                harkInstanceRef.current = hark(stream);
                harkInstanceRef.current.on('speaking', speakingOnListener(id));
                harkInstanceRef.current.on('stopped_speaking', speakingOffListener(id));
            }
            else {
                setSpeakingParticipants((prev) => (Object.assign(Object.assign({}, prev), { [id]: false })));
            }
        };
        meeting.participants.joined
            .toArray()
            .map((participant) => audioUpdateListener(participant)); // initialization
        // Listening to mute/unmute
        meeting.participants.joined.on('audioUpdate', audioUpdateListener);
        return () => {
            meeting.participants.joined.off('audioUpdate', audioUpdateListener);
            cleanupHarkInstance();
        };
    }, [
        cleanupHarkInstance,
        meeting.participants.joined,
        speakingOffListener,
        speakingOnListener,
    ]);
    const filteredParticipants = useMemo(() => {
        return Object.keys(speakingParticipants).filter((id) => speakingParticipants[id]);
    }, [speakingParticipants]);
    return filteredParticipants;
}
