import React, { useCallback } from 'react';
import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core';
import _ from 'lodash';

import { SatisfactionSurvey } from '@components/index';
import { SinglePanelWithMessaging } from '@components/Layout';

import useClinicConfig from '@hooks/useClinicConfig';

import { useExtendedTranslation } from '@services/i18nService';
import { ackSurvey, setSurvey } from '@services/PatronService';

import IPatron, { SatisfactionScore } from '@typings/IPatron';
import { formatPhoneNumber } from '@util/formatPhoneNumber';

const useStyle = makeStyles({
  fullHeight: {
    height: '100%',
  },
  flexGrow: {
    display: 'flex',
    flexGrow: 1,
  },
  thankYouStyle: {
    fontSize: '24px',
    fontStyle: 'italic',
    width: '80%',
  },
});

export function Survey({
  patron,
  onContinue,
}: {
  patron: IPatron;
  onContinue?: () => void;
}) {
  const t = useExtendedTranslation();
  const clinicConfig = useClinicConfig();
  const hasPhoneExtension = !_.isEmpty(_.get(clinicConfig, 'phoneNoExt', ''));
  const formattedNumber = formatPhoneNumber(clinicConfig.phoneNo);

  const CLASSES = useStyle();

  const onRating = useCallback(
    (s: SatisfactionScore) => {
      setSurvey(patron.properties.surveyId, s);
    },
    [patron.properties.surveyId]
  );

  const onContinueCb = useCallback(() => {
    ackSurvey(patron.id);
    onContinue?.();
  }, [onContinue, patron.id]);

  return (
    <Box display="flex" flex={1}>
      <SinglePanelWithMessaging
        patron={patron}
        title={<></>}
        footer={
          <Grid container justifyContent="center">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={onContinueCb}
              >
                {t('questionnaire.continue')}
              </Button>
            </Grid>
          </Grid>
        }
      >
        <Grid
          container
          direction="column"
          className={CLASSES.fullHeight}
          spacing={3}
        >
          <Grid item>
            <Typography color="primary">
              {t(hasPhoneExtension ? 'survey.reachIfExt' : 'survey.reachIf', {
                phoneNo: formattedNumber,
                phoneNoExt: clinicConfig.phoneNoExt,
              })}
            </Typography>
          </Grid>

          <Grid item>
            <Typography color="primary">{t('survey.surveyQ')}</Typography>
            <Box marginLeft={1} marginTop={2}>
              <Grid container justifyContent="center">
                <Grid item sm={8} xs>
                  <SatisfactionSurvey onSelectSatisfactionScore={onRating} />
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item className={CLASSES.flexGrow}>
            <Box
              display="flex"
              justifyContent="center"
              alignContent="flex-end"
              flexWrap="wrap"
              flexGrow="1"
            >
              <Typography
                color="primary"
                align="center"
                className={CLASSES.thankYouStyle}
              >
                {t('survey.thankyou')}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </SinglePanelWithMessaging>
    </Box>
  );
}
