var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useRef } from 'react';
import { logger } from '../utils';
// Turns on/off camera and microphone on meeting initialization
export function useAudioVideo(meeting, isMicOn, isCameraOn) {
    const isMounted = useRef(true);
    const isProcessing = useRef(false);
    useEffect(() => {
        const manageAudioVideo = () => __awaiter(this, void 0, void 0, function* () {
            if (isProcessing.current) {
                return;
            }
            isProcessing.current = true;
            try {
                if (isMounted.current) {
                    if (isMicOn) {
                        yield meeting.self.enableAudio();
                    }
                    else {
                        yield meeting.self.disableAudio();
                    }
                    if (isCameraOn) {
                        yield meeting.self.enableVideo();
                    }
                    else {
                        yield meeting.self.disableVideo();
                    }
                }
            }
            catch (error) {
                if (isMounted.current) {
                    logger(error, 'Error managing audio/video:', { level: 'error' });
                }
            }
            finally {
                isProcessing.current = false;
            }
        });
        if (meeting) {
            manageAudioVideo();
        }
        // Call it once, on meeting init
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meeting]);
}
