import { useEffect, useMemo } from 'react';
import { useImmer } from 'use-immer';
import { useDyteMeeting } from '@dytesdk/react-web-core';
export const isHost = (participant) => {
    var _a;
    return ((_a = participant.customParticipantId) === null || _a === void 0 ? void 0 : _a[0]) === 'H';
};
export function useHost() {
    var _a;
    const { meeting } = useDyteMeeting();
    const joinedParticipants = useMemo(() => meeting.participants.joined.toArray(), [meeting.participants.joined]);
    const [host, setHost] = useImmer((_a = joinedParticipants.find(isHost)) !== null && _a !== void 0 ? _a : null);
    useEffect(() => {
        const participantJoined = (participant) => {
            if (!host && isHost(participant)) {
                setHost(participant);
            }
        };
        const participantLeft = (participant) => {
            var _a;
            if (isHost(participant)) {
                const newHost = (_a = joinedParticipants.find(isHost)) !== null && _a !== void 0 ? _a : null;
                setHost(newHost);
            }
        };
        meeting.participants.joined.on('participantJoined', participantJoined);
        meeting.participants.joined.on('participantLeft', participantLeft);
        return () => {
            meeting.participants.joined.off('participantJoined', participantJoined);
            meeting.participants.joined.off('participantLeft', participantLeft);
        };
    }, [
        host,
        joinedParticipants,
        meeting.participants.joined,
        meeting.self,
        setHost,
    ]);
    return host;
}
