import { useDyteMeeting } from '@dytesdk/react-web-core';
import { useImmer } from 'use-immer';
import { useEffect } from 'react';
import { useHost } from './useHost';
import { useActiveSpeaker } from './useActiveSpeaker';
export function useThirdSpeaker() {
    const { meeting } = useDyteMeeting();
    const joinedParticipants = meeting.participants.joined.toArray();
    const host = useHost();
    const [thirdSpeaker, setThirdSpeaker] = useImmer(joinedParticipants.find((participant) => {
        // not host and not self
        return participant.id !== meeting.self.id && participant.id !== (host === null || host === void 0 ? void 0 : host.id);
    }));
    const [activeSpeaker] = useActiveSpeaker();
    useEffect(() => {
        if (!activeSpeaker && !thirdSpeaker) {
            // set third speaker to be the second host if they exist or the first non-host participant that is also not self
            setThirdSpeaker(joinedParticipants.find((participant) => {
                return (participant.id !== meeting.self.id && participant.id !== (host === null || host === void 0 ? void 0 : host.id));
            }));
        }
        else if (activeSpeaker &&
            activeSpeaker.id !== meeting.self.id &&
            activeSpeaker.id !== (host === null || host === void 0 ? void 0 : host.id)) {
            // set third speaker to be the active speaker if it is not the host or self
            setThirdSpeaker(activeSpeaker);
        }
    }, [
        activeSpeaker,
        host,
        joinedParticipants,
        meeting.self.id,
        setThirdSpeaker,
        thirdSpeaker,
    ]);
    return thirdSpeaker;
}
