import React, { KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useFormikContext } from 'formik';
import moment from 'moment';

import InQStyleGuide from '@constants';

import { useExtendedTranslation } from '@services/i18nService';


const useStyles = makeStyles({
  dateFieldContainer: {
    fontSize: '1rem',
    borderRadius: '4px',
    padding: '0.5rem',
    fontFamily: 'monospace',
  },
  fieldDivider: {
    display: 'inline',
  },
  input: {
    border: 'none',
    backgroundColor: 'transparent',
    fontSize: '1rem',
    fontFamily: 'monospace',
    paddingRight: 0,
    paddingLeft: '0.5ch',
  },
});

function parseDateString(dateString: string) {
  const [year, month, day] = dateString.split('-');
  return {
    year,
    month,
    day,
  };
}

export function FormikNumericDateField({
  id,
  error,
  required = false,
  initialValue,
}: {
  id: string;
  error: boolean;
  required: boolean;
  initialValue?: string;
}) {
  const maxYear = 9999; // TODO: fix before year 10k
  const minYear = 0;
  const maxMonth = 12;
  const minMonth = 1;
  const maxDay = 31;
  const minDay = 1;

  const t = useExtendedTranslation();
  const yearFormat = t('checkin.year');
  const monthFormat = t('checkin.month');
  const dayFormat = t('checkin.day');

  const yearDTType = 'YYYY';
  const monthDTType = 'MM';
  const dayDTType = 'DD';

  const [year, setYear] = useState(yearFormat);
  const [month, setMonth] = useState(monthFormat);
  const [day, setDay] = useState(dayFormat);

  const yearRef = useRef<HTMLInputElement>(null);
  const monthRef = useRef<HTMLInputElement>(null);
  const dayRef = useRef<HTMLInputElement>(null);

  const CLASSES = useStyles();

  const { setFieldValue } = useFormikContext();
  useEffect(() => {
    if (!initialValue) {
      return;
    }

    const {
      year: parsedYear,
      month: parsedMonth,
      day: parsedDay,
    } = parseDateString(initialValue);
    setYear(parsedYear);
    setMonth(parsedMonth);
    setDay(parsedDay);
  }, [initialValue]);

  useEffect(() => {
    setFieldValue(id, `${year}-${month}-${day}`);
  }, [day, id, month, setFieldValue, year]);

  useEffect(() => {
    if (year === '' || !/^\d+$/.test(year)) {
      setYear(yearFormat);
    }
  }, [year, yearFormat]);

  useEffect(() => {
    if (month === '' || !/^\d+$/.test(month)) {
      setMonth(monthFormat);
    }
  }, [month, monthFormat]);

  useEffect(() => {
    if (day === '' || !/^\d+$/.test(day)) {
      setDay(dayFormat);
    }
  }, [day, dayFormat]);

  const handleKeyDown = (
    e: KeyboardEvent<HTMLDivElement>,
    format: string,
    dtType: string,
    min: number,
    max: number,
    current: string,
    updateFn: (value: React.SetStateAction<string>) => void,
    leftField: null | React.RefObject<HTMLInputElement>,
    rightField: null | React.RefObject<HTMLInputElement>
  ) => {
    if (e.key === 'ArrowUp') {
      if (current === format) {
        updateFn(moment().format(dtType));
      } else {
        updateFn((prev) => {
          const prevValue = parseInt(prev, 10);
          if (prevValue >= max) {
            return String(min).padStart(format.length, '0');
          }
          return String(prevValue + 1).padStart(format.length, '0');
        });
      }
      e.preventDefault();
    }

    if (e.key === 'ArrowDown') {
      if (current === format) {
        updateFn(moment().format(dtType));
      } else {
        updateFn((prev) => {
          const prevValue = parseInt(prev, 10);
          if (prevValue <= min) {
            return String(max).padStart(format.length, '0');
          }
          return String(prevValue - 1).padStart(format.length, '0');
        });
      }
      e.preventDefault();
    }

    if (e.key === 'Backspace' && current === format) {
      e.preventDefault();
    }

    if (e.key === 'ArrowLeft') {
      e.preventDefault();
      if (leftField) {
        leftField.current?.focus();
      }
    }

    if (e.key === 'ArrowRight') {
      e.preventDefault();
      if (rightField) {
        rightField.current?.focus();
      }
    }
  };

  const handleYearKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      handleKeyDown(
        e,
        yearFormat,
        yearDTType,
        minYear,
        maxYear,
        year,
        setYear,
        null,
        monthRef
      );
    },
    [year, yearFormat]
  );

  const handleMonthKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      handleKeyDown(
        e,
        monthFormat,
        monthDTType,
        minMonth,
        maxMonth,
        month,
        setMonth,
        yearRef,
        dayRef
      );
    },
    [month, monthFormat]
  );

  const handleDayKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      handleKeyDown(
        e,
        dayFormat,
        dayDTType,
        minDay,
        maxDay,
        day,
        setDay,
        monthRef,
        null
      );
    },
    [day, dayFormat]
  );

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    format: string,
    dtType: string,
    updateFn: (value: React.SetStateAction<string>) => void
  ) => {
    const nextValue = parseInt(e.target.value.replaceAll(/[^0-9]/g, ''), 10);
    if (!Number.isFinite(nextValue)) {
      updateFn(moment().format(dtType));
    }
    updateFn(
      String(nextValue)
        .slice(-1 * format.length)
        .padStart(format.length, '0')
    );
  };

  const handleYearChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleFieldChange(e, yearFormat, yearDTType, setYear);
    },
    [yearFormat]
  );

  const handleMonthChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleFieldChange(e, monthFormat, monthDTType, setMonth);
    },
    [monthFormat]
  );

  const handleDayChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleFieldChange(e, dayFormat, dayDTType, setDay);
    },
    [dayFormat]
  );

  return (
    <div
      style={
        error
          ? {
              border: `1px solid ${InQStyleGuide.red.dark}`,
            }
          : {
              border: `1px solid ${InQStyleGuide.grey.medium}`,
            }
      }
      className={CLASSES.dateFieldContainer}
      role="group"
    >
      <input
        style={{
          width: `${yearFormat.length + 1}ch`,
        }}
        className={CLASSES.input}
        ref={yearRef}
        value={year}
        onKeyDown={handleYearKeyDown}
        onChange={handleYearChange}
        inputMode="numeric"
        pattern="[d]{0,4}"
        role="spinbutton"
        tabIndex={0}
        aria-label={`${t('checkin.aria.year')}`}
        aria-invalid={false}
        aria-valuetext={year === yearFormat ? t('checkin.aria.empty') : year}
        aria-valuemin={minYear}
        aria-valuemax={maxYear}
        aria-required={required}
      />
      <div className={CLASSES.fieldDivider} aria-hidden>
        /
      </div>
      <input
        style={{
          width: `${monthFormat.length + 1}ch`,
        }}
        className={CLASSES.input}
        ref={monthRef}
        value={month}
        onKeyDown={handleMonthKeyDown}
        onChange={handleMonthChange}
        inputMode="numeric"
        pattern="[0-1][d]"
        role="spinbutton"
        tabIndex={0}
        aria-label={`${t('checkin.aria.month')}`}
        aria-invalid={false}
        aria-valuetext={month === monthFormat ? t('checkin.aria.empty') : month}
        aria-valuemin={minMonth}
        aria-valuemax={maxMonth}
        aria-required={required}
      />
      <div className={CLASSES.fieldDivider} aria-hidden>
        /
      </div>
      <input
        style={{
          width: `${dayFormat.length + 1}ch`,
        }}
        className={CLASSES.input}
        ref={dayRef}
        value={day}
        onKeyDown={handleDayKeyDown}
        onChange={handleDayChange}
        inputMode="numeric"
        pattern="[0-3][d]"
        role="spinbutton"
        tabIndex={0}
        aria-label={`${t('checkin.aria.day')}`}
        aria-invalid={false}
        aria-valuetext={day === dayFormat ? t('checkin.aria.empty') : day}
        aria-valuemin={minDay}
        aria-valuemax={maxDay}
        aria-required={required}
      />
    </div>
  );
}
